<form class="form w-100 p-2">
  <div mat-dialog-title class="dialog-title mx-6">
    <h2 class="text-gray-800 fw-bold" translate="migrateAccountPopup.title"></h2>

    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="pt-2" [innerHTML]="'migrateAccountPopup.body' | translate"></mat-dialog-content>

  <mat-dialog-actions>
    <div class="d-flex flex-row w-100 gap-2">
      <button mat-stroked-button color="primary" class="flex-fill" mat-dialog-close>
        <span class="indicator-label" translate="misc.close"></span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
