import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiAlertError } from '../decorators/api-alert-error.decorator';
import { AuthModel, AuthModelJson } from '../models/auth.model';

const API_AUTH_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<AuthModel> {
    return this.http
      .post<AuthModelJson>(`${API_AUTH_URL}/login`, {
        email,
        password
      })
      .pipe(map((auth: AuthModelJson) => AuthModel.fromJson(auth)));
  }

  @ApiAlertError()
  register(
    name: string,
    email: string,
    password: string,
    locale: string,
    newsletters: boolean,
    cohorts: boolean,
    surveys: boolean,
    dataUsage: boolean,
    emailSharing: boolean
  ): Observable<AuthModel> {
    return this.http
      .post<AuthModelJson>(`${API_AUTH_URL}/register`, {
        name,
        email,
        password,
        locale,
        email_consents: {
          newsletters,
          cohorts,
          surveys,
          data_usage: dataUsage,
          email_sharing: emailSharing
        }
      })
      .pipe(map((auth: AuthModelJson) => AuthModel.fromJson(auth)));
  }

  @ApiAlertError()
  refreshToken(refreshToken: string): Observable<AuthModel> {
    const headers = new HttpHeaders({
      skip: 'true',
      Authorization: 'Bearer ' + refreshToken
    });

    return this.http
      .post<AuthModelJson>(`${API_AUTH_URL}/auth/refresh-token`, null, { headers })
      .pipe(map((auth: AuthModelJson) => AuthModel.fromJson(auth)));
  }

  @ApiAlertError()
  logout(): Observable<unknown> {
    return this.http.post(`${API_AUTH_URL}/logout`, null);
  }

  @ApiAlertError()
  forgetPassword(email: string): Observable<string> {
    return this.http
      .post(`${API_AUTH_URL}/forget-password`, { email })
      .pipe(map((response: { message: string }) => response.message));
  }

  @ApiAlertError()
  resetPassword(email: string, token: string, password: string): Observable<string> {
    return this.http
      .post(`${API_AUTH_URL}/forget-password/reset`, { email, token, password })
      .pipe(map((response: { message: string }) => response.message));
  }

  @ApiAlertError()
  onboardingRequest(comment: string): Observable<void> {
    return this.http.post<void>(`${API_AUTH_URL}/onboarding-requests`, {
      type: 'professional',
      comment
    });
  }
}
