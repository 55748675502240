import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, finalize, tap } from 'rxjs';
import { AuthApiService } from 'src/app/core/api-services/auth.api-service';
import { MigrateAccountDialogData } from './migrate-account-dialog-data';

@Component({
    selector: 'app-migrate-account-dialog',
    templateUrl: './migrate-account-dialog.component.html',
    styleUrls: ['./migrate-account-dialog.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule
    ]
})
export class MigrateAccountDialogComponent implements OnInit {
  email: string;

  constructor(
    public dialogRef: MatDialogRef<MigrateAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MigrateAccountDialogData,
    private authApiService: AuthApiService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.email = data.email;
  }

  ngOnInit(): void {
    this.resetPassword();
  }

  resetPassword() {
    combineLatest([this.authApiService.forgetPassword(this.email), this.translateService.get('misc.close')])
      .pipe(tap(([message, close]) => this.snackbar.open(message, close, { duration: 3000 })))
      .subscribe();
  }
}
