import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiAlertError } from '../decorators/api-alert-error.decorator';
import { User, UserJson } from '../models/user.model';
import { OnboardingRequest, OnboardingRequestJson } from '../models/onboarding-request.model';

const API_USERS_URL = `${environment.apiUrl}/users`;
const API_EMAIL_VERIFICATION_URL = `${environment.apiUrl}/email/verify`;
const API_ONBOARDING_REQUESTS_URL = `${environment.apiUrl}/onboarding-requests`;

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  @ApiAlertError([401])
  getUser(): Observable<User> {
    return this.http.get<UserJson>(`${API_USERS_URL}/me`).pipe(map((user: UserJson) => User.fromJson(user)));
  }

  @ApiAlertError()
  resendEmailVerification(): Observable<void> {
    return this.http.post<void>(`${API_EMAIL_VERIFICATION_URL}/resend`, null);
  }

  @ApiAlertError()
  patchUser(user: Partial<User>): Observable<unknown> {
    return this.http.put<UserJson>(`${API_USERS_URL}`, User.toJson(user));
  }

  @ApiAlertError()
  patchUserPassword(password: string, passwordConfirmation: string): Observable<unknown> {
    return this.http.put<void>(`${API_USERS_URL}`, { password, password_confirmation: passwordConfirmation });
  }

  @ApiAlertError()
  getOnboardingRequests(): Observable<OnboardingRequest[]> {
    return this.http
      .get<OnboardingRequestJson[]>(API_ONBOARDING_REQUESTS_URL)
      .pipe(
        map((onboardingRequests: OnboardingRequestJson[]) =>
          onboardingRequests.map((onboardingRequest: OnboardingRequestJson) =>
            OnboardingRequest.fromJson(onboardingRequest)
          )
        )
      );
  }
}
