import { Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  currentLang = signal<string>('');

  constructor(private translateService: TranslateService) {}

  init(): void {
    const browserLang = this.translateService.getBrowserLang();
    const storedLang = localStorage.getItem('language');

    if (storedLang) {
      this.setLanguage(storedLang);
    } else if (browserLang) {
      this.setLanguage(browserLang);
    } else {
      this.setLanguage('en');
    }
  }

  setLanguage(language: string): void {
    localStorage.setItem('language', language);

    this.translateService.use(language);
    moment.locale(language);
    this.currentLang.set(language);
  }
}
